
import { defineComponent } from 'vue'
import StoryCard from './StoryCard.vue'
import MasonryWall from '@yeger/vue-masonry-wall'
import { StoryObj } from '@/store/stories/types'

export default defineComponent({
  name: 'StoriesList',
  props: {
    stories: Array,
    loaded: Boolean,
    message: String
  },
  components: {
    StoryCard,
    MasonryWall
  },
  computed: {
    storiesArr: function () {
      return this.stories as Array<StoryObj>
    }
  }
})
