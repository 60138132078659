import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stories container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StoryCard = _resolveComponent("StoryCard")!
  const _component_MasonryWall = _resolveComponent("MasonryWall")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_MasonryWall, {
          key: 0,
          items: _ctx.storiesArr,
          "column-width": 600,
          gap: 0
        }, {
          default: _withCtx(({ item }) => [
            _createVNode(_component_StoryCard, {
              key: item.id,
              class: _normalizeClass('story card '),
              storyProp: (item as any)
            }, null, 8, ["storyProp"])
          ]),
          _: 1
        }, 8, ["items"]))
      : _createCommentVNode("", true)
  ]))
}