
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import ArrayJoin from '@/components/ArrayJoin.vue'
import StoriesList from '@/components/StoriesList.vue'
import { StoryObj } from '@/store/stories/types'
import { ImageAttachmentObj } from '@/store/images/types'
import Markdown from 'vue3-markdown-it'

export default defineComponent({
  name: 'Story',
  components: {
    ArrayJoin,
    Markdown,
    StoriesList
  },
  computed: {
    ...mapGetters({
      loaded: 'getStoriesFeaturedLoaded',
      stories: 'getStoriesFeaturedData',
      message: 'getStoriesMessage',
      relatedData: 'getStoriesRelatedData',
      relatedLoaded: 'getStoriesRelatedLoaded',
      relatedMessage: 'getStoriesRelatedMessage'
    })
  },
  data () {
    return {
      storyTitle: this.$route.params.storyTitle
    }
  },
  mounted () {
    this.loadStory()
    this.scrollToTop()
  },
  watch: {
    $route (to, from) {
      if (
        this.$route.params.storyTitle &&
        to.params.storyTitle !== from.params.storyTitle
      ) {
        this.loadStory()
        this.scrollToTop()
      }
    },
    loaded: function (newVal) {
      if (newVal && this.$route.params.storyTitle !== '') {
        this.stories[0].relatedStories.forEach(() => {
          this.$store.dispatch('loadRelatedStoriesData', `SEARCH("${this.stories[0].title}", ARRAYJOIN(relatedStories, ""))`)
        })
      }
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    loadStory () {
      this.$store.dispatch('setFeaturedStoriesLoadedState', false)
      this.$store.dispatch('getStoriesData', {
        store: 'featured',
        type: 'filterBy',
        query: `SEARCH("${this.$route.params.storyTitle}", {title})`
      })
      if (this.loaded) {
        this.stories[0].relatedStories.forEach((story:StoryObj) => {
          this.$store.dispatch('getStoriesData', {
            store: 'featured',
            type: 'filterBy',
            query: `SEARCH("${story}", {id})`
          })
        })
      }
    },
    /*
    * Finds references in text following the ~item-index~ patten
    * and replaces with corresponding linked media
    */
    injectLinkedMedia (story:string, linkedQuotes:Array<string>, linkedImages:Array<ImageAttachmentObj>) {
      const regex = /~[^~]*~+/g
      const findReference = story.match(regex)
      if (findReference) {
        const referenceObject = findReference.reduce((obj:any, item:string) => {
          const indexNumber = item.match(/\d+/g)
          const indexSimple = Number(indexNumber) - 1
          const isQuote = item.search('quote') >= 0
          const isImage = item.search('image') >= 0
          const isPortrait = linkedImages[indexSimple].height > linkedImages[indexSimple].width
          const format = isPortrait ? 'portrait' : 'landscape'
          if (isQuote) { return { ...obj, [item]: `> ${linkedQuotes[indexSimple]}` } }
          if (isImage) { return { ...obj, [item]: `![image](${linkedImages[indexSimple].url}#${format})` } }
        }, 0)
        const regexFromMyArray = new RegExp(findReference?.join('|'), 'gi')
        const injectedStory = story.replace(regexFromMyArray, function (matched:string) {
          return referenceObject[matched]
        })
        return injectedStory
      } else {
        return story
      }
    }
  }
})
